import React, { useEffect, useState } from 'react';
import { Box, Center, Spinner, Link, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

const FormPage = () => {
  const { workspaceId, agentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  console.log('Component rendered. Workspace ID:', workspaceId, 'Agent ID:', agentId);

  useEffect(() => {
    console.log('First useEffect triggered');
    const fetchFormDetails = () => {
      console.log('Fetching form details...');
      // Simulating an asynchronous operation
      setTimeout(() => {
        console.log('Form details fetched, setting isLoading to false');
        setIsLoading(false);
      }, 1000);
    };

    fetchFormDetails();
  }, []);

  useEffect(() => {
    console.log('Second useEffect triggered. isLoading:', isLoading);
    if (!isLoading) {
      console.log('Not loading, proceeding with script injection');
      // Remove any existing form div content to avoid duplication
      const formDiv = document.getElementById(`clientbase-form-${agentId}`);
      if (formDiv) {
        console.log('Existing form div found, clearing its content');
        formDiv.innerHTML = '';
      } else {
        console.log('Form div not found');
      }

      // Check if the script is already added
      const existingScript = document.querySelector(`script[src="https://clientbaseserver.onrender.com/embed.js"][data-workspace-id="${workspaceId}"][data-agent-id="${agentId}"]`);
      if (!existingScript) {
        console.log('Script not found, creating and appending it');
        const script = document.createElement('script');
        script.src = "https://clientbaseserver.onrender.com/embed.js";
        script.async = true;
        script.dataset.workspaceId = workspaceId;
        script.dataset.agentId = agentId;
        document.body.appendChild(script);

        console.log('Script appended to body');

        script.onload = () => {
          console.log('Script loaded successfully');
        };

        script.onerror = () => {
          console.error('Error loading script');
        };

        return () => {
          console.log('Cleanup: Removing script');
          document.body.removeChild(script);
        };
      } else {
        console.log('Script already exists, not appending');
      }
    }
  }, [isLoading, workspaceId, agentId]);

  if (isLoading) {
    console.log('Rendering loading spinner');
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  console.log('Rendering form container');
  return (
    <Box minH="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={4}>
      <Center width="100%" maxW="800px" flex="1">
        <div id={`clientbase-form-${agentId}`} style={{ width: '100%' }}>
          {console.log('Form container rendered')}
        </div>
      </Center>
      <Box textAlign="center" py={4}>
        <Text>
          Powered by <Link href="https://app.clientbaseai.com" isExternal color="blue.500">clientbase</Link>
        </Text>
      </Box>
    </Box>
  );
};

export default FormPage;