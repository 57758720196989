import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import FormPage from './FormPage';

const RedirectToExternal = () => {
  useEffect(() => {
    window.location.href = 'https://app.clientbaseai.com/login';
  }, []);

  return null;
};

const App = () => {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/form/:workspaceId/:agentId" element={<FormPage />} />
          <Route path="/" element={<RedirectToExternal />} />
          <Route path="*" element={<RedirectToExternal />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
